import { httpClient } from '@/api/httpClient';

export class CallerVerifyAPI {
    constructor(baseURL = null, timeout = null, auth = null) {
        this.httpClient = new httpClient(baseURL, timeout, auth);
    }

    setAuthManager(auth) {
        this.httpClient.setAuthManager(auth);
    }

    async ping() {
        // make a backend ping request, without any authorization token.
        const {error, status_code, data} = await this.httpClient.get(`/ping`, 5000, true /* no auth */);
        if(error == null && status_code == 200 && data.status) {
            return {status: true}
        }
        else {
            return {status: false}
        }
    }

    async getBaseConfig() {
        // get the authenticated configuration.
        const {error, status_code, data} = await this.httpClient.get(`/config/base`, 5000, true /* no auth */);
        if(error == null && status_code == 200 && data) {
            return {status: true, settings: data.config}
        }
        else {
            let errorMessage = data?data.message:error.message
            console.error("Error reading initialization data: " + errorMessage);
            return {status: false, settings: this.getBaseConfigDefault()}
        }
    }

    async getSettings() {
        // get the authenticated configuration.
        const {error, status_code, data} = await this.httpClient.get(`/config/settings`);
        if(error == null && status_code == 200 && data) {
            return {status: true, settings: data.config}
        }
        else {
            let errorMessage = data?data.message:error.message
            console.error("Failed to load application config (" + errorMessage + "), using defaults.");
            return {status: false, settings: this.getSettingsDefault()}
        }
    }

    async getUsers(attr, value, firstName, lastName) {
        let queryString = null

        // build up the required query string based on the requested selected attribute.
        if(attr === 'firstAndLastName') {
            queryString = `attr=firstAndLastName&firstName=${encodeURIComponent(firstName||'')}&lastName=${encodeURIComponent(lastName||'')}`;
        }
        else {
            queryString = `attr=${encodeURIComponent(attr||'')}&value=${encodeURIComponent(value||'')}`;
        }

        // make the actual API call.
        const {error, status_code, data} = await this.httpClient.get(`/users?${queryString}`);

        // successfully got at least one user
        if(error == null && status_code == 200) {
            // convert response to old v1 style so we don't have to rewrite the front end.
            let users = {};
            data.users.forEach((user) => {
                users[user.id] = user;
            })
            return {status: true, notification: null, users: users, attrs: data.optionalResultAttrs};
        }
        // no users found.
        else if(error && status_code == 404) {
            let title = 'Caller(s) Not Found';
            let message = this.getMessage(data, error, 'No user records found for specified criteria.');
            return {status: false, notification: this.getNotification(status_code, title, message, 'info'), users: {}, attrs: []};
        }
        // other errors.
        else {
            let message = this.getMessage(data, error, 'An error occurred with the lookup request.');
            return {status: false, notification: this.getNotification(status_code, null, message), users: {}, attrs: []};
        }

    }

    async getUserByAttr(attr, value) {
        let queryString = null

        if (!value || !attr) {
            return { status: false, notification: null, user: {} };
        } 
        // when this is called, supposedly the attr should contain a default value, e.g. profile.login, however, to make it more reliable,
        // we deal with this attr empty case to make this function able to accommodate more scenarios. DQY
        // build up the required query string based on the requested selected attribute.
        queryString = `attr=${encodeURIComponent(attr || '')}`;
        // make the actual API call.
        var requesturl = `/users/${value}?${queryString}`;
        const { error, status_code, data } = await this.httpClient.get(requesturl);
        
        var shortAttr = '';
        // pull the 'profile.' off the attribute
        let dotIndex = attr.indexOf(".");
        shortAttr = attr.substring(dotIndex+1);
        if (error == null && status_code == 200) {
            // convert response to old v1 style so we don't have to rewrite the front end.

            let theuser = {};
            
            theuser = data['user'];
            var factors = theuser.mfa.factors;
            var adjustedfactors = {};
            for (const onefactor of factors) {
                adjustedfactors[onefactor.id] = onefactor;
            }
            theuser.mfa.factors = adjustedfactors;
            return { status: true, notification: null, user: theuser };
        }
        // no users found.
        else if (error && status_code == 404) {
            // try to include the manager id in the info

            let title = 'Unable to verify with manager';
            let message = "Manager not found with '%{ attrName }' = '%{ attrValue }'";
            let notificationmsg = this.getNotification(status_code, title, message, null, { attrName: shortAttr, attrValue: value });
            return { status: false, notification: notificationmsg, user: {} };
        }
        // other errors.
        else if (error && status_code == 403) {
            // message includes "too many results"
            // do a message including manager id like above
            // this should be the info message
            let title = 'Unable to verify with manager';
            let message = "Too many matches found with manager '%{ attrName }' = '%{ attrValue }'";
            let notification = this.getNotification(status_code, title, message, null, { attrName: shortAttr, attrValue: value });
            return { status: false, notification: notification, user: {} };

        }
        else {
            // this can be as is
            let message = this.getMessage(data, error, 'An error occurred with the lookup request.');
            return { status: false, notification: this.getNotification(status_code, null, message), user: {} };
        }
    }

    async getUserFactors(userId, userStatus = null) {
        // get the caller's factors include the callers status to save a lookup
        let getFactorsQueryParams = '';
        if(userStatus) {
            getFactorsQueryParams = `?userStatus=${encodeURIComponent(userStatus||'')}`;
        }

        // make the actual API call.
        const {error, status_code, data} = await this.httpClient.get(`/users/${userId}/factors${getFactorsQueryParams}`);

        // factors retrieved successfully.
        if(error == null && status_code == 200) {
            // convert response to old v1 style so we don't have to rewrite the front end.
            let factors = {}
            data.factors.forEach((factor) => {
                factors[factor.id] = factor
            })
            return {status: true, notification: null, factors: factors}
        }
        else {
            let notification = null;
            if(data) {
                // for errors only return a notification if we got back a specific message (e.g. not the case it was a timeout.)
                notification = this.getNotification(status_code, 'Factor(s) Not Loaded', data.message);
            }
            return {status: false, notification: notification, factors: {}};
        }
    }

    async factorChallenge(userId, factorId, factorType, delegatorUID=null) {

        let challengeBody = { };
        if(delegatorUID){
            challengeBody['delegatorUID'] = delegatorUID;
        }
        const { error, status_code, data } = await this.httpClient.post(`/users/${userId}/factors/${factorId}/verify`, challengeBody);

        // verification challenge successfully triggered.
        if(error == null && status_code == 200 && (data?.result === 'CHALLENGE' || data?.result === 'WAITING')) {
            let challengeResponse =  {
                transactionId: data?.transactionId, // only for push/ciba/device/oob flows.
                challengeCode: data?.challengeCode, // number challenge.
                expiresAt: data?.expiresAt,         // only for push/ciba/device flows.
                message: data?.message,
                result: data?.result
            };
            return {status: true, notification: null, challengeResponse: challengeResponse};
        }
        // failed to send the verification challenge, handle the error.
        else {
            // provide a relevant title for the error notification.
            let title = null;
            let defaultError = null;
            if(factorType == 'sms') {
                title = 'SMS OTP Verification Failed';
                defaultError = 'An error occurred while attempting to send the OTP code.';
            }
            else if(factorType == 'email') {
                title = 'Email OTP Verification Failed';
                defaultError = 'An error occurred while attempting to send the OTP code.';
            }
            else {
                title = 'Caller Verify Failed';
                defaultError = 'An error occurred while attempting to trigger the push notification.';
            }
            let message = this.getMessage(data, error, defaultError);
            return {status: false, notification: this.getNotification(status_code, title, message), challengeResponse: null};
        }
    }

    async factorChallengeCIBA(userId, factorId, login, locale = 'en_US', message_id = null, customMessage = null, delegatorUID = null) {

        // start with the base request body.
        let challengeBody = {
            login: login,
            locale: locale
        };
        if(delegatorUID){
            challengeBody['delegatorUID'] = delegatorUID;
        }
        // determine the message (if any) to include in the request.
        //
        // prefer the custom message if specified.
        if(customMessage && customMessage.trim() !== '') {
            challengeBody['message'] = customMessage;
        }
        // otherwise use a predefined selected message (if any)
        else if(message_id) {
            challengeBody['message_id'] = message_id;
        }

        const {error, status_code, data} = await this.httpClient.post(`/users/${userId}/factors/${factorId}/verify`, challengeBody);

        // verification challenge successfully triggered.
        if(error == null && status_code == 200 && data?.result === 'WAITING') {
            let challengeResponse =  {
                transactionId: data?.transactionId, // only for push/ciba/device flows.
                expiresAt: data?.expiresAt, // only for push/ciba/device flows.
                message: data?.message,
                result: data?.result
            };
            return {status: true, notification: null, challengeResponse: challengeResponse};
        }
        // failed to send the verification challenge, handle the error.
        else {
            // provide a relevant title for the error notification.
            let title = 'Caller Verify Failed';
            let message = this.getMessage(data, error, null /* no default */);
            return {status: false, notification: this.getNotification(status_code, title, message), challengeResponse: null};
        }
    }

    async factorChallengeDevice(userId, factorId, deliveryMethod, delegatorUID=null, webhookId=null) {

        // use the selected delivery method.
        let challengeBody = { delivery: deliveryMethod };
        if (delegatorUID) {
            challengeBody['delegatorUID'] = delegatorUID;
        }
        if(webhookId){
            challengeBody['webhookId'] = webhookId;
        }
        const {error, status_code, data} = await this.httpClient.post(`/users/${userId}/factors/${factorId}/verify`, challengeBody, 20000);

        // verification challenge successfully triggered.
        if(error == null && status_code == 200 && data?.result === 'WAITING') {
            let challengeResponse =  {
                transactionId: data?.transactionId, // only for push/ciba/device flows.
                expiresAt: data?.expiresAt, // only for push/ciba/device flows.
                verificationUrl: data?.verificationUrl, // only for device flow
                message: data?.message,
                result: data?.result
            };
            return {status: true, notification: null, challengeResponse: challengeResponse};
        }
        // failed to send the verification challenge, handle the error.
        else {
            // provide a relevant title for the error notification.
            let title = 'Device Verify Failed';
            let message = this.getMessage(data, error, null /* no default */);
            return {status: false, notification: this.getNotification(status_code, title, message), challengeResponse: null};
        }
    }

    async factorVerify(userId, factorId, factorType, callerResponse, transactionId, delegatorUID=null) {

        // build payload based on factor type.
        let verifyPayload = null;
        if(factorType === 'question') {
            verifyPayload = { answer: callerResponse };
        }
        else {
            verifyPayload = { code: callerResponse };
        }
        // if there is a transaction id, include it in the request.
        if (transactionId) {
            verifyPayload.transactionId = transactionId;
        }
        if(delegatorUID){
            verifyPayload['delegatorUID']=delegatorUID;
        }
        // OTP oob flows can take a bit longer, so allow for that.
        const timeout = 20000;
        const {error, status_code, data} = await this.httpClient.post(`/users/${userId}/factors/${factorId}/verify`, verifyPayload, timeout);

        // verification challenge successfully triggered.
        if(error == null && status_code == 200) {
            let message = this.getMessage(data, null, 'Successfully verified factor.');
            return {status: true, notification: null, message: message};
        }
        // 403 - this could be one of two things:
        //       (1) Verification failure -- most likely.
        //       (2) Factor is disabled -- less likely but possible as it would be hard trigger.
        //
        // We need to determine which one in order to present the proper error back, to do
        // this we check the error_id for factor_disabled.
        else if (status_code == 403 && (this.getErrorId(data) !== 'factor_disabled')) {
            let message = this.getMessage(data, null, 'The callers response was incorrect.')
            return {status: false, notification: null, message: message};
        }
        // API call failed - provide a notification.
        else {
            // provide a relevant title for the error notification.
            let title = 'Caller Verify Failed';
            let message = this.getMessage(data, error, 'An error occurred while attempting to verify the caller.');
            return {status: false, notification: this.getNotification(status_code, title, message), message: message};
        }
    }

    async factorPoll(userId, factorId, transactionId, login = null, delegatorUID = null) {

        let pollParams = '';
        if(login) {
            pollParams = `?login=${encodeURIComponent(login)}`;
            if(delegatorUID){
                let dele_param = `&delegatorUID=${delegatorUID}`;
                pollParams = pollParams+dele_param;
            }
        }
        else{
            if(delegatorUID){
                let dele_param = `?delegatorUID=${delegatorUID}`;
                pollParams = pollParams + dele_param;
            }
        }
        const {error, status_code, data} = await this.httpClient.get(`/users/${userId}/factors/${factorId}/transactions/${transactionId}${pollParams}`);

        // verification challenge successfully triggered.
        if(error == null && status_code == 200 && data?.result) {
            let message = this.getMessage(data, null, null);
            return {status: true, notification: null, result: data.result, message: message};
        }
        // failed to send the verification challenge, handle the error.
        else {
            // provide a relevant title for the error notification.
            let title = 'Caller Verify Failed';
            let message = this.getMessage(data, error, null /* no default message */);
            let notification = this.getNotification(status_code, title, message);

            // 401 on device poll could be invalid user logged in using the device token.
            // in this case we don't want to include the auth button and we want the dialog
            // to auto-dismiss.
            if(status_code == 401 && data.result == 'FAILED') {
                notification.showAuthBtn = false;
            }
            return {status: false, notification: notification, result: data?.result, message: message};
        }
    }

    async factorEnroll(userId, factorType, factorProvider, factorUri, forceEnroll = false) {
        // start with the base enrollment body.
        let enrollBody = {
            type: factorType,
            provider: factorProvider,
            activate: forceEnroll
          };

        // build the enrollment body based on the type of factor.
        if(factorType === 'sms') {
            enrollBody.phoneNumber = factorUri;
        }
        else if(factorType === 'email') {
            enrollBody.email = factorUri;
        }

        const {error, status_code, data} = await this.httpClient.post(`/users/${userId}/factors/enroll`, enrollBody);

        if(error == null && status_code == 200) {
            let notification = null;
            // response from initial enrollment was ACTIVE so this means that
            // either:
            //
            // a) forceEnrollment was used to bypass confirmation.
            // b) SMS re-enrollment of the same number was used (Okta "Feature")
            //
            if (data.factor.status === 'ACTIVE') {
                let message = this.getMessage(data, null, 'Successfully enrolled factor.');
                // Since okta skips the verification for when re-enrolling the same
                // SMS number, we need to let the user know this happened so they
                // don't get confused.
                //
                if(!forceEnroll && factorType == 'sms') {
                    message = 'Re-enrollment of the callers previous number.  Confirmation has been bypassed.';
                }
                let title = 'Factor Enroll Success';
                notification = this.getNotification(status_code, title, message);
            }
            return {status: true, notification: notification, factor: data.factor}
        }
        else {
            let title = 'Factor Enrollment Failed';
            let message = this.getMessage(data, error, 'An error occurred while attempting to enroll the factor.');
            let notification = this.getNotification(status_code, title, message);
            return {status: false, notification: notification, factor: null}
        }
    }

    async factorActivate(userId, factorId, challengeCode) {

        let activateBody =  {
            code: challengeCode
        };
        const {error, status_code, data} = await this.httpClient.post(`/users/${userId}/factors/${factorId}/activate`, activateBody);

        if(error == null && status_code == 200) {
            let notification = null;
            if (data.factor.status === 'ACTIVE') {
                let title = 'Factor Enroll Success';
                let message = this.getMessage(data, null, 'Successfully enrolled factor.');
                notification = this.getNotification(status_code, title, message);
            }
            return {status: true, notification: notification, factor: data.factor}
        }
        // 403 - is not an error but a verification failure, so stop here - no notification required.
        else if (status_code == 403) {
            return {status: false, notification: null, factor: null};
        }
        else {
            let title = 'Factor Enrollment Failed';
            let message = this.getMessage(data, error, 'An error occurred while attempting to enroll the factor.');
            let notification = this.getNotification(status_code, title, message);
            return {status: false, notification: notification, factor: null}
        }

    }

    async factorResetSingle(userId, factorId) {
        const {error, status_code, data} = await this.httpClient.post(`/users/${userId}/factors/${factorId}/reset`);

        if(error == null && status_code == 200) {
            let title = 'Factor Reset Success'
            let message = this.getMessage(data, null, 'Successfully reset factor.');
            let notification = this.getNotification(status_code, title, message);
            return {status: true, notification: notification};
        }
        else {
            let title = 'Factor Reset Failed';
            let message = this.getMessage(data, error, 'An error occurred attempting to reset factor.');
            let notification = this.getNotification(status_code, title, message);
            return {status: false, notification: notification};
        }
    }

    async factorResetAll(userId) {
        const {error, status_code, data} = await this.httpClient.post(`/users/${userId}/factors/reset`);

        if(error == null && status_code == 200) {
            let title = 'Factor Reset Success'
            let message = this.getMessage(data, null, 'Successfully reset all factors.');
            let notification = this.getNotification(status_code, title, message);
            return {status: true, notification: notification};
        }
        else {
            let title = 'Factor Reset Failed';
            let message = this.getMessage(data, error, 'An error occurred attempting to reset all factors.');
            let notification = this.getNotification(status_code, title, message);
            return {status: false, notification: notification};
        }
    }

    async unlockProfile(userId) {
        const {error, status_code, data} = await this.httpClient.post(`/users/${userId}/unlock`);

        if(error == null && status_code == 200) {
            let title = 'Unlock Successful'
            let message = this.getMessage(data, null, 'Unlock succeeded');
            let notification = this.getNotification(status_code, title, message);
            return {status: true, notification: notification};
        }
        else {
            let title = 'Unlock Failed';
            let message = this.getMessage(data, error, 'Unlock failed to process');
            let notification = this.getNotification(status_code, title, message);
            return {status: false, notification: notification};
        }
    }

    async resetPassword(userId, resetMethod) {
        let resetBody =  { 'method': resetMethod };
        const {error, status_code, data} = await this.httpClient.post(`/users/${userId}/password/reset`, resetBody);

        if(error == null && status_code == 200) {
            let notification = null;
            let temporaryPassword = null;
            // for link method we just display a notification
            if(resetMethod == 'link') {
                let title = 'Reset Successful'
                let message = this.getMessage(data, null, 'Password reset succeeded');
                notification = this.getNotification(status_code, title, message);
            }
            // for temp password/passphrase,return the temporary value.
            else {
                temporaryPassword = data.tempPassword;
            }
            return {status: true, notification: notification, tempPass: temporaryPassword}
        }
        else {
            let title = 'Reset Failed';
            let message = this.getMessage(data, error, 'Reset failed to process');
            let notification = this.getNotification(status_code, title, message);
            return {status: false, notification: notification, tempPass: null};
        }
    }

    async getLogs(userId, details = false) {
        let logsUrl = `/logs/${userId}`;

        // det detailed logs info if requested.
        if(details == true) {
            logsUrl = `/logs/${userId}/details`
        }

        const {error, status_code, data} = await this.httpClient.get(logsUrl);

        if(error == null && status_code == 200) {
            return {status: true, notification: null, events: data?.events};
        }
        else {
            let title = 'Error: Loading System Logs';
            let message = this.getMessage(data, error, 'An error occurred while loading system logs.');
            let notification = this.getNotification(status_code, title, message);
            return {status: false, notification: notification, events: []};
        }
    }

    async adminConfigList() {

        const {error, status_code, data} = await this.httpClient.get(`/admin/config`);

        if(error == null && status_code == 200) {
            return {status: true, notification: null, settings: data?.settings};
        }
        else {
            let title = 'Error: Loading Configuration Settings';
            let message = this.getMessage(data, error, 'An error occurred while loading configuration.');
            let notification = this.getNotification(status_code, title, message);
            return {status: false, notification: notification, settings: []};
        }
    }

    async adminConfigUpdate(setting, value, settingName) {

        // construct the body for updating the setting.
        let updateConfigBody = { id: setting, value: value };
        const {error, status_code, data} = await this.httpClient.post(`/admin/config`, updateConfigBody);

        if(error == null && status_code == 200) {

            let title = 'Configuration Update Success';

            let message = 'Successfully updated setting: %{ configSetting }.';
            let notification = this.getNotification(status_code, title, message, null, {configSetting: settingName});
            notification.showReload = true;
            notification.timeout = -1; // make the notification stick around so its actioned on.

            return {status: true, notification: notification};
        }
        else {
            let title = 'Configuration Update Failed';
            let message = this.getMessage(data, error, 'Configuration update failed');
            let notification = this.getNotification(status_code, title, message);
            return {status: false, notification: notification};
        }
    }

    async reportList() {
        // get a list of supported reports ids.
        const {error, status_code, data} = await this.httpClient.get('/reports', null, true);

        if(error == null && status_code == 200) {
            return {status: true, notification: null, reportList: data.reports};
        }
        else {
            let title = 'Error Loading Reports';
            let message = this.getMessage(data, error, 'An error occurred while loading reports.');
            let notification = this.getNotification(status_code, title, message);
            return {status: false, notification: notification, reportList: []};
        }
    }

    async reportGet(reportId, selectedMonth) {
        let queryString = '';

        // include the selected month (if there was one).
        if(selectedMonth) {
            queryString = `?selectedMonth=${encodeURIComponent(selectedMonth)}`;
        }

        // make the actual API call.
        const {error, status_code, data} = await this.httpClient.get(`/reports/${reportId}${queryString}`);

        if(error == null && status_code == 200) {
            return {status: true, notification: null, headers: data.headers, output: data.output};
        }
        // report not found
        else if(error && status_code == 404) {
            let title = 'Error Loading Report';

            // DOC: The api returns the message in a non-translateable format, so we can't use that
            // directly here.  As a result we cannot use getMessage() when the API returns a 404 error
            // and instead we just use static message as a base.
            let message = 'Unknown report matching: "%{ selectedReport }"';
            let notification = this.getNotification(status_code, title, message, null, {selectedReport: reportId});
            return {status: false, notification: notification, headers: [], output: []};
        }
        // other errors.
        else {
            let title = 'Error Loading Report';
            let message = this.getMessage(data, error, 'Failed to get report details');
            let notification = this.getNotification(status_code, title, message);
            return {status: false, notification: notification, headers: [], output: []};
        }
    }

    async reportDownload(reportId, selectedMonth) {
        // make the actual API call to download the report.
        const {error, status_code, data} = await this.httpClient.get(`/reports/${reportId}/download?selectedMonth=${encodeURIComponent(selectedMonth)}`);
        if(error == null && status_code == 200) {
            return {status: true, notification: null, filename: data.filename, reportCSV: data.blob};
        }
        else if (error && status_code == 404) {
            let title = 'Report Download';

            // DOC: The api returns the message in a non-translateable format, so we can't use that
            // directly here.  As a result we cannot use getMessage() when the API returns a 404 error
            // and instead we just use static message as a base.
            let message = 'Unknown report matching: "%{ selectedReport }"';
            let notification = this.getNotification(status_code, title, message, null, {selectedReport: reportId});
            return {status: false, notification: notification, filename: null, reportCSV: null}
        }
        else {
            let title = 'Report Download';
            let message = this.getMessage(data, error, 'Failed to download report');
            let notification = this.getNotification(status_code, title, message);
            return {status: false, notification: notification, filename: null, reportCSV: null}
        }

    }

    // get the message to use for the notification order of preference:
    //
    // 1) The "message" in the response body (if we have one)
    // 2) The default message, if we have a response body, but no "message" field.
    // 3) No response body, use the message in the HTTP error.
    // 4) No response body, no message in HTTP error, return null (allow getNotification to decide)
    //
    getMessage(response, error, defaultMsg) {
        let message = (response?(response.message||defaultMsg):(error.message||null));
        return message;
    }

    getErrorId(response) {
        let error_id = (response?(response.error_id||null):null);
        return error_id;
    }

    getNotification(status_code, title = null, message = null, type = null, variables = null) {
        let notification = {}
        if(status_code === 401) {
            notification = {
                title: title || 'Token Expired',
                body: message || 'Invalid request token, please re-login.',
                type: type || 'error',
                timeout: -1,
                showAuthBtn: true,
                variables: variables
            }
        }
        else {
            notification =  {
                title: title || 'Request Error',
                body: message || 'Unknown error.',
                type: type || (status_code === 200 ? 'success' : 'error'),
                timeout: null,
                showAuthBtn: false,
                variables: variables
              }
        }
        return notification
    }

    getSettingsDefault() {
        return this.settings = {
          'featureFlags': {
            'enableUnlock': false,
            'enableFactorResetSingle': false,
            'enableFactorResetAll': false,
            'enableFactorEnrollment': false,
            'enableLogDetails': false,
            'obfuscateMFACredIDs': true,
            'quickVerifyFactor': 'OKTA:push',
            'deviceFlowDeliverySMS': false,
            'deviceFlowDeliveryEmail': false,
            'riskyUsersThreshold': 3,
          },
          'logDeltaHours': 24,
          'attributes': [
            {
              "text": "Login (username)",
              "value": "profile.login"
            },
            {
              "text": "Primary email address",
              "value": "profile.email"
            },
            {
              "text": "Primary phone number",
              "value": "profile.primaryPhone"
            },
            {
              "text": "First and last name",
              "value": "firstAndLastName"
            }
          ],
          'selectedAttr': 'profile.email'
        };
    }

    getBaseConfigDefault() {
        // just provide some basic defaults so we can produce a proper error page.
        return {
            'client_id': "#",
            'issuer': "http://localhost",
            'appVersion': 'Unknown',            // FIXME: Embed this front-end version at compile time
            'customer': 'TechJutsu Inc.',
            'customerLogoHeight': '32',
            'customerLogoWidth': '32',
            'customerLogoUri': '/static/img/techjutsu-t-logo.png',     // FIXME: Not a viable widget default.
            'customerLogoDarkUri': '/static/img/techjutsu-t-logo.png', // fIXME: Not a viable widget default.

            // default theme colors for dark theme
            'appTheme': {
                'dark': {
                    'error': "FF5252",
                    'info': "2196F3",
                    'primary': "2196F3",
                    'secondary': "424242",
                    'success': "4CAF50",
                    'warning': "FB8C00"
                },
                'light': {
                    'error': "FF5252",
                    'info': "2196F3",
                    'primary': "1976D2",
                    'secondary': "424242",
                    'success': "4CAF50",
                    'warning': "FFC107"
                },
                'loginGradient': {
                    'end': "590000",
                    'start': "A60000"
                }
            }
        };
    }

}
