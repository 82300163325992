<template>
  <v-container fluid>
    <v-container style="max-width: 900px;">
        <v-card>
          <v-card-title>
            <span id="admin_config_title" v-translate>Caller Verify Configuration</span>
            <v-spacer></v-spacer>
            <v-text-field
              id="admin_config_search"
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="config"
            :search="search"
            :loading="configLoading"
            group-by="category"
          >
            <template v-slot:top="{pagination, options, updateOptions}">
              <v-data-footer
                :pagination="pagination"
                :options="options"
                @update:options="updateOptions"
                items-per-page-text="$vuetify.dataTable.itemsPerPageText">
              </v-data-footer>
              <v-dialog
                v-model="dialog"
                max-width="500px"
              >
                <v-card>
                  <v-card-title>
                    <span id="cv_edit_config" class="text-h5" v-translate='{config: $cvutils.getTranslatedConfigID(selectedItem.id)}'>Edit: %{ config }</span>
                  </v-card-title>
                  <v-card-subtitle class="ma-2">{{ $cvutils.getTranslatedConfigDesc(selectedItem.id) }}</v-card-subtitle>

                  <v-card-text>
                    <v-container>
                      <v-row
                        v-if="selectedItem.type === 'image'"
                      >
                        <v-img
                          :src=selectedItem.value
                          :max-height="$appConfig.customerLogoHeight"
                          :max-width="$appConfig.customerLogoWidth"
                        >
                        </v-img>
                      </v-row>
                      <v-row justify="center">
                        <v-text-field
                          v-if="selectedItem.type === 'string'"
                          v-model="selectedItem.value"
                        ></v-text-field>
                        <v-switch
                          v-if="selectedItem.type === 'bool'"
                          v-model="selectedItem.value"
                          :label="selectedItem.value?$gettext('Enabled'):$gettext('Disabled')"
                        >
                        </v-switch>
                        <v-color-picker
                          v-if="selectedItem.type === 'color'"
                          v-model="selectedItem.value"
                          mode="hexa"
                        ></v-color-picker>
                        <v-text-field
                          v-if="selectedItem.type === 'image'"
                          v-model="selectedItem.value"
                        ></v-text-field>
                        <v-select
                          v-if="selectedItem.type === 'enum'"
                          v-model="selectedItem.value"
                          :items="getRestrictedValues(selectedItem.id, selectedItem.valid_values)"
                          item-value="value"
                          item-text="display"
                        ></v-select>
                        <v-slider
                          v-if="selectedItem.type === 'int'"
                          v-model="selectedItem.value"
                          :min="selectedItem.min"
                          :max="selectedItem.max"
                          hide-details
                          class="align-center"
                        >
                          <template v-slot:append>
                            <v-text-field
                              v-model="selectedItem.value"
                              class="mt-0 pt-0"
                              hide-details
                              single-line
                              filled
                              dense
                              rounded
                              type="number"
                              style="width: 100px"
                            ></v-text-field>
                          </template>
                        </v-slider>
                        <v-text-field
                          v-if="selectedItem.type === 'password'"
                          v-model="selectedItem.value"
                          :type="showSecret ? 'text' : 'password'"
                        >
                          <template v-slot:append>
                            <!-- control if the caller response input field is starred out or not -->
                            <v-icon @click="showSecret = !showSecret">
                              {{ showSecret ? 'mdi-eye' : 'mdi-eye-off' }}
                            </v-icon>
                          </template>
                        </v-text-field>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="success"
                      :loading="saveInProgress"
                      @click="save"
                    >
                      Save
                    </v-btn>
                    <v-btn
                      color="primary"
                      @click="close"
                      :disabled="saveInProgress"
                    >
                      Cancel
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template v-slot:item.id="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{on, attrs}">
                  <div v-bind="attrs" class="ma-3" v-on="on">{{ $cvutils.getTranslatedConfigID(item.id) }}</div>
                </template>
                <span>{{ $cvutils.getTranslatedConfigDesc(item.id) }}</span>
              </v-tooltip>
            </template>
            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template v-slot:item.value="{ item }">
              <v-chip
                v-if="item.type === 'color'"
                :color="item.value"
                text-color="white"
              >
              {{ item.value }}
              </v-chip>
              <div
                v-if="item.type === 'password'"
              >
              *********************************
              </div>
              <div v-if="item.type === 'image'"
              >
              <v-img
                :src=item.value
                :max-height="$appConfig.customerLogoHeight"
                :max-width="$appConfig.customerLogoWidth"
              >
              </v-img>
              </div>
              <div
                v-if="item.type === 'int'"
              >
              {{ item.value }}
              </div>
              <div
                v-if="item.type === 'string'"
              >
                <v-tooltip top>
                  <template v-slot:activator="{on, attrs}">
                    <span v-bind="attrs" v-on="on">{{ truncate(item.value) }}</span>
                  </template>
                  <span>{{ item.value }}</span>
                </v-tooltip>
              </div>
              <div
                v-if="item.type == 'enum'"
              >
              <!-- use display value for enum's-->
              {{ $cvutils.getTranslatedConfigID(`${item.id}.${item.value}`) }}
              </div>
              <div
                v-if="item.type === 'bool'"
              >
                <div>
                  <v-tooltip top>
                    <template v-slot:activator="{on, attrs}">
                      <v-icon v-if="item.value" v-bind="attrs" v-on="on" color="green">mdi-check-circle-outline</v-icon>
                      <v-icon v-else v-bind="attrs" v-on="on" color="red">mdi-close-circle-outline</v-icon>
                    </template>
                    <span>
                      <translate :key="item.id + '_enabled'" v-if="item.value">Enabled</translate>
                      <translate :key="item.id + '_disabled'" v-else>Disabled</translate>
                    </span>
                  </v-tooltip>
                </div>
              </div>
            </template>

            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template v-slot:group.header="{items}">
              <th colspan="4">
                {{ $cvutils.getTranslatedCategoryID(items[0].category) }}
              </th>
            </template>

            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template v-slot:item.actions="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
    </v-container>

    <NetworkStatus />
    <NotificationDialog
      v-model="showNotification"
      :notification="notification"
      @close="showNotification = false"
    />
  </v-container>
</template>

<script>
import NetworkStatus from '@/components/NetworkStatus';
import NotificationDialog from '@/components/NotificationDialog';


export default {
  name: 'AdminConfig',

  components: {
    NotificationDialog,
    NetworkStatus
  },


  data: () => ({
    selectedItem: {},
    selectedIndex: -1,
    dialog: false,
    showSecret: false,
    configLoading: false,
    saveInProgress: false,
    search: '',
    // order of category groups on the admin configuration page.
    categoryOrder: ['app_theme.light',
                    'app_theme.dark',
                    'app_theme.login_gradient',
                    'customer',
                    'feature_flags',
                    'delegation',
                    'oob',
                    'misc',
                    'jira',
                    'device',
                    'ciba',
                    'ciba.message_default',
                    'idp',
                    'oidc',
                    'oidc.introspect',
                   ],
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: false,
        value: "id"
      },
      { text: "Value",
        align: "start",
        sortable: false,
        value: "value",
      },
      { text: "Action",
        align: "end",
        sortable: false,
        value: "actions",
      }
    ],
    config: [],

    // Notification dialog settings.
    showNotification: false,
    notification: {
      showAuthBtn: false,
      showReload: false,
      timeout: 5000,
      title: '',
      body: '',
      type: 'info'
    }

  }),
  methods: {
    editItem(item) {
      this.selectedIndex = this.config.indexOf(item);
      this.selectedItem = Object.assign({}, item);
      this.showSecret = false;
      this.dialog = true;
    },
    close () {
      this.dialog = false;
      this.selectedIndex = -1;
      this.selectedItem = {};
    },
    truncate(text) {
      let maxlength = 30;
      let truncated = text;
      if(text.length > maxlength) {
        truncated = truncated.substring(0, maxlength) + '...';
      }
      return truncated;
    },
    async save () {
      if(this.selectedIndex > -1) {

        // put a loading spinner on the save button.
        this.saveInProgress = true;

        // pre-translate the setting name for the notification dialog.
        const translatedSetting = this.$cvutils.getTranslatedConfigID(this.selectedItem.id);
        const {status, notification} = await this.$cvApi.adminConfigUpdate(this.selectedItem.id, this.selectedItem.value, translatedSetting);

        this.saveInProgress = false;

        if(status) {
            // update the UI with the changed values for this item.
            Object.assign(this.config[this.selectedIndex], this.selectedItem);
        }

        if(notification) {
          this.notify(notification);
        }

        // close the edit dialog.
        this.close();
      }
    },
    async load() {
      this.configLoading = true;
      this.search = '';
      const {status, notification, settings} = await this.$cvApi.adminConfigList();

      if(status) {
        let tempConfig = settings;
        tempConfig.forEach((setting) => {
          // prefix the category with NN_ where NN is the sort order.
          let categoryPrefix = this.categoryOrder.indexOf(setting.category).toString().padStart(2, "0");
          setting.category = `${categoryPrefix}_${setting.category}`
        })
        this.config = tempConfig;
      }
      else {
        if(notification) {
          this.$emit('notify', notification);
        }
      }
      // stop the config loading indicator.
      this.configLoading = false;
    },
    getRestrictedValues(config, valid_values) {
      let translated_rvs = [];
      valid_values.forEach((rv) => {
        translated_rvs.push({
          display: this.$cvutils.getTranslatedConfigID(`${config}.${rv}`),
          value: rv
        });
      });
      return translated_rvs;
    },
    notify(notification) {
      if (!notification) {
        return;
      }
      this.notification = notification
      this.showNotification = true;
    }
  },
  mounted() {
    this.load();
  }
}
</script>


<style>/**
 * Temporary fix for breaking change in Vuetify v-grid
 * (see : https://github.com/vuetifyjs/vuetify/issues/11408)
 * TODO - remove this after migration
 */
.row:not([class*="my-"]):not([class*="ma-"]):not([class*="mt-"]):not([class*="mb-"]) {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}</style>
