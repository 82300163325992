<!-- eslint-disable vue/no-unused-vars -->
<template>
  <v-container fluid>
    <v-container style="max-width: 65%;">
      <v-row justify-space-around align-content-center>
        <v-col>
          <v-select
            id="cv_selected_report"
            v-model="selectedReport"
            :label="$gettext('Usage Report to view')"
            @change="loadReport()"
            :items="usageReports"
          >
            <template v-slot:item="{ item }">
              <option :value="item.value">
                <translate :key="item.value">{{ item.text }}</translate>
              </option>
            </template>
            <template v-slot:selection="{ item }">
              <translate :key="item.value">{{ item.text }}</translate>
            </template>
          </v-select>
        </v-col>

        <v-col>
          <v-select
            id="cv_selected_month"
            v-model="selectedMonth"
            :label="$gettext('Report Month')"
            @change="loadReport()"
            :items="monthSelections"
          >
            <template v-slot:item="{ item }">
              <option :id="'cv_month_' + item.value" :value="item.value">
                {{ translateMonthEntry(item.text) }}
              </option>
            </template>
            <template v-slot:selection="{ item }">
              {{ translateMonthEntry(item.text) }}
            </template>
          </v-select>
        </v-col>

        <v-col>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$gettext('Filter results')"
            single-line
            hide-details
          >
          </v-text-field>
        </v-col>

        <v-col
          v-if="this.reportOutput.length > 0"
          sm="1" md="1" lg="1"
          class="mt-3">
          <v-icon
            large
            :title="$gettext('Report Download')"
            @click="downloadReport()"
          >mdi-download-circle-outline</v-icon>
        </v-col>
      </v-row>

      <v-data-table
        id="cv_usage_reports_table"
        :headers="headers"
        :items="reportOutput"
        :loading="reportLoading"
      >
        <template
          v-for="header in headers"
          v-slot:[`header.${header.value}`]="{ header }"
        >
          <translate :key="header.value">{{ header.text }}</translate>
        </template>
        <template v-slot:item="{ item, headers }">
          <tr>
            <td v-for="header in headers" :key="header.text">
              <span v-if="header.translate">
                <translate :key="item.id">{{ item[header.value] }}</translate>
              </span>
              <span v-else>
                {{ item[header.value] }}
              </span>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-container>

    <!-- Hidden download link element -->
    <a ref="downloadLink" class="hide" :href="dataURL" :download="dataFilename">Report Download</a>

    <NetworkStatus />

    <NotificationDialog
      v-model="showNotification"
      :notification="notification"
      @close="showNotification = false"
    />

  </v-container>
</template>

<script>
import { translate } from 'vue-gettext';

import NetworkStatus from '@/components/NetworkStatus';
import NotificationDialog from '@/components/NotificationDialog';

// eslint-disable-next-line no-unused-vars
const { gettext: $gettext, gettextInterpolate} = translate;

export default {
  name: 'UsageReports',

  components: {
    NetworkStatus,
    NotificationDialog
  },

  // Component properties
  //
  // settings - additional configuration settings for reports including:
  // * feature flags enabled/disabled.
  //
  props: {
    settings: Object
  },

  data: () => ({
    usageReports: [],
    selectedReport: '',
    reportLoading: false,
    dataURL: '#',
    dataFilename: 'unknown',

    monthNames: [
      "January", "February", "March", "April", "May", "June", "July", "August",
      "September", "October", "November", "December"],
    monthSelections: [],
    selectedMonth: '',

    search: '',
    headers: [],
    reportOutput: [],

    errorMessage: '',

    showNotification: false,
    notification: {
      showAuthBtn: false,
      timeout: 5000,
      title: '',
      body: '',
      type: 'info',
      variables: null
    }
  }),

  methods: {
    async downloadReport() {
      const {status, notification, filename, reportCSV} = await this.$cvApi.reportDownload(this.selectedReport, this.selectedMonth);
      if(status) {
        const blob = new Blob([reportCSV], {type: 'text/csv;charset=utf-8;'});
        const url = URL.createObjectURL(blob);
        this.dataURL = url;
        this.dataFilename = filename;

        // Delay click
        setTimeout(() => {
          this.$refs.downloadLink.click();
        }, 600);

        setTimeout(() => {
          URL.revokeObjectURL(url);
        }, 5000);
      }
      else {
        if(notification) {
          this.notify(notification);
        }
      }
    },
    filterString(value) {
      let found = true;
      if (!this.search || this.search.length === 0) {
        return found;
      }

      // Catch any errors and abort filtering
      try {
        found = $gettext(value).toLowerCase().includes(this.search.toLowerCase());
      } catch {
        found = true;
      }
      return found;
    },
    async loadReport() {

      this.headers = [];
      this.reportOutput = [];

      if (!this.selectedReport) {
        return;
      }

      // start the report loading spinner.
      this.reportLoading = true;

      const {status, notification, headers, output} = await this.$cvApi.reportGet(this.selectedReport, this.selectedMonth);
      if(status) {
        this.headers = headers;
        this.reportOutput = output;
        // Check header data for custom filtering (supports translated values)
        this.headers.forEach(header => {
          if (header.filterable) {
            header.filter = this.filterString;
          }
        });
      }
      else {
        // FIXME KDK: make sure the translated notifications are correct -- include report id.
        if(notification) {
          this.notify(notification);
        }
      }

      // report is no longer loading -- stop the spinner.
      this.reportLoading = false;
    },
    translateMonthEntry(value) {
      // Translate the given value (without year)
      const parts = value.split(' ');
      if (parts.length < 2) {
        return $gettext('Unknown');
      }

      const month = parts[0];
      const year = parts[1];
      if (parts.length >= 3) {
        // This month
        const thisMonth = $gettext('this month');
        return `${$gettext(month)} ${year} (${thisMonth})`;
      }

      return `${$gettext(month)} ${year}`;
    },
    notify(notification) {
      if (!notification) {
        return;
      }
      this.notification = notification
      this.showNotification = true;
    },
    setupMonthDropdown() {
      // Generate a 12 month window for month selection
      let monthDiff = 0;
      while (monthDiff <= 11) {
        const dateObj = new Date();
        dateObj.setMonth(dateObj.getMonth() - monthDiff);

        const monthName = this.monthNames[dateObj.getMonth()];
        let text = `${monthName} ${dateObj.getFullYear()}`;
        let month = (dateObj.getMonth() + 1 < 10 ? '0' : '') + (dateObj.getMonth() + 1);
        const value = `${dateObj.getFullYear()}-${month}`;

        // Special name for current month
        if (dateObj.getMonth() === new Date().getMonth()) {
          text = `${text} (this month)`;
          this.selectedMonth = value;
        }
        this.monthSelections.push({
          'text': text,
          'value': value
        });

        monthDiff += 1;
      }
    },
    async load() {
      const {status, notification, reportList} = await this.$cvApi.reportList();

      if(status) {
        this.usageReports = reportList;

        // If only a single report is available, load it
        if (this.usageReports.length === 1) {
          this.selectedReport = this.usageReports[0].value;
          this.loadReport();
        }
      }
      else {
        if(notification) {
          this.notify(notification);
        }
      }
      // Populate the month selection dropdown
      this.setupMonthDropdown();
    }
  },
  mounted() {
    this.load();
  }
}
</script>


<style scoped>
/**
 * Temporary fix for breaking change in Vuetify v-grid
 * (see : https://github.com/vuetifyjs/vuetify/issues/11408)
 * TODO - remove this after migration
 */
.row:not([class*="my-"]):not([class*="ma-"]):not([class*="mt-"]):not([class*="mb-"]) {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.v-list-item {
  cursor: pointer;
}

.v-list-item:hover {
  background-color: #eeeeee;
}
</style>
